// src/hooks/useConfiguration.tsx
import { useCallback } from 'react';
import axios from 'axios';
import { useToken } from './useToken';
import config from '../common/config';
import {
    PersonalView,
    UserConfiguration,
} from '../common/interfaces';

export const useConfiguration = () => {
    const { getToken } = useToken();

    const getUserConfiguration = useCallback(async (userId: string): Promise<UserConfiguration | null> => {
        try {
            const token = await getToken();
            const response = await axios.get(
                `${config.apiEndpoint}/easydirectory/configurations/${userId}`,
                {
                    headers: {
                        Authorization: `Bearer ${token?.token}`,
                        'Content-Type': 'application/json',
                    },
                }
            );
            return response.data;
        } catch (e) {
            console.error(e);
            return null;
        }
    }, [getToken]);

    const updatePersonalView = useCallback(async (
        userId: string,
        updatedView: PersonalView
    ): Promise<UserConfiguration | null> => {
        try {
            const token = await getToken();
            const response = await axios.patch(
                `${config.apiEndpoint}/easydirectory/configurations/${userId}/personalView/${updatedView.id}`,
                updatedView,
                {
                    headers: {
                        Authorization: `Bearer ${token?.token}`,
                        'Content-Type': 'application/json',
                    },
                }
            );
            return response.data;
        } catch (e) {
            console.error(e);
            return null;
        }
    }, [getToken]);

    const postPersonalView = useCallback(async (
        userId: string,
        view: PersonalView
    ): Promise<UserConfiguration | null> => {
        try {
            const token = await getToken();
            const response = await axios.post(
                `${config.apiEndpoint}/easydirectory/configurations/${userId}/personalViews`,
                view,
                {
                    headers: {
                        Authorization: `Bearer ${token?.token}`,
                        'Content-Type': 'application/json',
                    },
                }
            );
            return response.data;
        } catch (e) {
            console.error(e);
            return null;
        }
    }, [getToken]);

    const deletePersonalView = useCallback(async (
        userId: string,
        viewId: string
    ): Promise<UserConfiguration | null> => {
        try {
            const token = await getToken();
            const response = await axios.delete(
                `${config.apiEndpoint}/easydirectory/configurations/${userId}/personalViews/${viewId}`,
                {
                    headers: {
                        Authorization: `Bearer ${token?.token}`,
                        'Content-Type': 'application/json',
                    },
                }
            );
            return response.data;
        } catch (e) {
            console.error(e);
            return null;
        }
    }, [getToken]);

    const updateDefaultView = useCallback(async (
        userId: string,
        viewId: string
    ): Promise<UserConfiguration | null> => {
        try {
            const token = await getToken();
            const response = await axios.patch(
                `${config.apiEndpoint}/easydirectory/configurations/${userId}/default/${viewId}`,
                {},
                {
                    headers: {
                        Authorization: `Bearer ${token?.token}`,
                        'Content-Type': 'application/json',
                    },
                }
            );
            return response.data;
        } catch (e) {
            console.error(e);
            return null;
        }
    }, [getToken]);

    const postFavorite = useCallback(async (
        userId: string,
        favoriteId: string,
    ): Promise<UserConfiguration | null> => {
        try {
            const token = await getToken();
            const response = await axios.post(
                config.apiEndpoint +
                '/easydirectory/configurations/' +
                userId +
                '/favorites',
                favoriteId,
                {
                    headers: {
                        Authorization: token?.token,
                        'Content-Type': 'application/json',
                    },
                },
            );

            return response.data;
        } catch (e) {
            console.log(e);
            return null;
        }
    }, [getToken]);

    const deleteFavorite = useCallback(async (
        userId: string,
        favoriteId: number,
    ): Promise<UserConfiguration | null> => {
        try {
            const token = await getToken();
            const response = await axios.delete(
                config.apiEndpoint +
                '/easydirectory/configurations/' +
                userId +
                '/favorites/' +
                favoriteId,
                {
                    headers: {
                        Authorization: token?.token,
                        'Content-Type': 'application/json',
                    },
                },
            );

            return response.data;
        } catch (e) {
            console.log(e);
            return null;
        }
    }, [getToken]);

    const postSyncContacts = useCallback(async (contactIds: string[]): Promise<void> => {
        try {
            const token = await getToken(["User.Read", "Contacts.ReadWrite"]);
            await axios.post(config.apiEndpoint + '/me/contacts/sync/', contactIds,
                {
                    headers: {
                        Authorization: `Bearer ${token?.token}`,
                        'Content-Type': 'application/json',
                    },
                },
            );
        } catch (e) {
            console.log(e);
        }
    }, [getToken]);

    const deleteSyncContacts = useCallback(async (contactId: string): Promise<void> => {
        try {
            const token = await getToken(["User.Read", "Contacts.ReadWrite"]);
            await axios.delete(config.apiEndpoint + '/me/contacts/sync/' + contactId,
                {
                    headers: {
                        Authorization: `Bearer ${token?.token}`,
                        'Content-Type': 'application/json',
                    },
                },
            );
        } catch (e) {
            console.log(e);
        }
    }, [getToken]);

    return {
        getUserConfiguration,
        updatePersonalView,
        postPersonalView,
        deletePersonalView,
        updateDefaultView,
        postFavorite,
        deleteFavorite,
        postSyncContacts,
        deleteSyncContacts,
    };
};