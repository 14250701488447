import {
  Button,
  makeStyles,
  Toast,
  ToastBody,
  ToastTitle,
  tokens,
  Tooltip,
} from '@fluentui/react-components';
import { CloudCheckmarkFilled, CloudRegular } from '@fluentui/react-icons';
import { forwardRef, useContext, useEffect, useImperativeHandle, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { PersonalView, SynchedContactRef, UserConfiguration } from '../common/interfaces';
import { useEasySearchProvider } from '../contexts/EasySearchContext';
import { useToast } from '../contexts/ToastContext';
import { EasyContext } from './EasyContext';
import { toPascalCase } from '../common/helpers';
import { EuiLoadingSpinner } from '@elastic/eui';
import { ViewTypes } from '../common/enums';
import { useToken } from '../hooks/useToken';
import { useConfiguration } from '../hooks/useConfiguration';

interface SynchedContactProps {
  contactId: string;
  displayName?: string;
  synchedContact?: any;
  onSyncToggle?: () => void;
}

const useClasses = makeStyles({
  iconColor: {
    color: tokens.colorBrandForeground1,
  },
});

export const SynchedContact = forwardRef<SynchedContactRef, SynchedContactProps>((props, ref) => {
  const { postSyncContacts, deleteSyncContacts } = useConfiguration();

  const { contactId, displayName } = props;
  const classes = useClasses();
  const { t } = useTranslation();
  const { dispatchToast } = useToast();
  const { selectedView } = useEasySearchProvider();

  const [synchedIndex, setSynchedIndex] = useState<number>();
  const [isSynched, setIsSynched] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { userConfig, dispatch } = useContext(EasyContext);

  useEffect(() => {
    return () => {
      setIsLoading(false);
    };
  }, [contactId]);

  useEffect(() => {
    // For an unknown reason, the keys of UserConfiguration gets loercased 
    const config: UserConfiguration = toPascalCase(userConfig);
    if (config?.SynchedContacts) {
      const synched = config.SynchedContacts.find((f) => f === contactId);
      if (synched) {
        setIsSynched(synched !== undefined);
        setSynchedIndex(
          synched
            ? config.SynchedContacts.findIndex((f) => f === contactId)
            : undefined,
        );
      }
    }
  }, [userConfig?.SynchedContacts, contactId, isLoading]);

  const updateSynchedContacts = async (synchedContacts: string[]) => {
    try {
      if (synchedContacts) {
        dispatch({
          type: 'UPDATE_SYNCHED_CONTACTS',
          payload: synchedContacts,
        });
      }
    } catch (error) {
      console.error('Error updating synched contacts:', error);
    }
  };

  useImperativeHandle(ref, () => ({
    addSynchedContact,
    setIsLoading,
    setIsSynched
  }));

  const addSynchedContact = async () => {
    setIsLoading(true);
    console.log('Starting add synched contact, current state:', isSynched);
    if (isSynched || !contactId) {
      !contactId && showToast(false, 'add');
      setIsLoading(false);
      return;
    }
    try {
      await postSyncContacts([contactId]);

      setIsSynched(true);
      updateSynchedContacts([contactId]);

      await handleSynchedView([contactId]);

      showToast(true, 'add', displayName);
    } catch (error) {
      console.error('Error adding synched contact:', error);
      showToast(false, 'add');
    } finally {
      setIsLoading(false);
    }
  };

  const removeSynchedContact = async () => {
    setIsLoading(true);
    if (synchedIndex === undefined) {
      setIsLoading(false);
      showToast(false, 'remove');
      return;
    }

    try {
      await deleteSyncContacts(contactId);

      setIsSynched(false);
      const config: UserConfiguration = toPascalCase(userConfig);
      updateSynchedContacts(config?.SynchedContacts.filter(contact => contact !== contactId) ?? []);
      await handleSynchedView(config?.SynchedContacts.filter(contact => contact !== contactId) ?? []);
      showToast(true, 'remove', displayName);

    } catch (error) {
      console.error('Error removing synched contact:', error);
      showToast(false, 'remove');
    } finally {
      setIsLoading(false);
    }
  };

  const handleSynchedView = async (synchedIds: string[]) => {
    if (selectedView?.id === 'synched_contacts') {
      const synchedView: PersonalView = {
        id: 'synched_contacts',
        viewType: ViewTypes.Standard,
        label: t('Views.Dropdown.SynchedText'),
        searchTemplate: JSON.stringify({
          searchTerm: '',
          filters: [
            {
              field: 'id',
              values: synchedIds,
              type: 'any',
            },
          ],
        }),
      };

      //setSelectedView(synchedView);
    }
  };

  const showToast = (
    success: boolean,
    action: 'add' | 'remove',
    label?: string,
  ) => {
    if (success) {
      dispatchToast(
        <Toast>
          <ToastTitle>
            {action === 'add'
              ? t('Notifications.Titles.View.Synched.Added')
              : t('Notifications.Titles.View.Synched.Removed')}
          </ToastTitle>
          <ToastBody>
            {action === 'add'
              ? t('Notifications.Contents.View.Synched.Added', { label })
              : t('Notifications.Contents.View.Synched.Removed', { label })}
          </ToastBody>
        </Toast>,
        { timeout: 3000, intent: 'success' },
      );
    } else {
      dispatchToast(
        <Toast>
          <ToastTitle>
            {t('Notifications.Titles.View.Synched.Error')}
          </ToastTitle>
          <ToastBody>
            {t('Notifications.Contents.View.Synched.Error')}
          </ToastBody>
        </Toast>,
        { timeout: 3000, intent: 'error' },
      );
    }
  };

  return (
    <Tooltip
      content={
        isSynched ? t('Main.Card.RemoveSynched') : t('Main.Card.AddSynched')
      }
      relationship="description"
    >
      <Button
        appearance="transparent"
        size="small"
        onClick={isSynched ? removeSynchedContact : addSynchedContact}
        icon={isLoading ? <EuiLoadingSpinner className={classes.iconColor} size="m" /> :
          isSynched ? (
            <CloudCheckmarkFilled className={classes.iconColor} />
          ) : (
            <CloudRegular className={classes.iconColor} />
          )
        }
      />
    </Tooltip >
  );
});
