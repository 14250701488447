import { useCallback, useState } from "react";
import { TeamsUserCredentialContext } from "../auth/singletonContext";
import { ErrorWithCode } from "@microsoft/teamsfx";

export const useToken = () => {
  const credential = TeamsUserCredentialContext.getInstance().getCredential();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);

  const getToken = useCallback(async (scopes: string[] = []) => {
    if (!credential) {
      throw new Error("No credential found");
    }

    setIsLoading(true);
    setError(null);

    try {
      const token = await credential.getToken(scopes);
      return token;
    } catch (err) {
      try {
        if (err instanceof ErrorWithCode) {
          await credential.login(scopes);
          return await credential.getToken(scopes);
        }
        throw err;
      } catch (loginErr) {
        setError(loginErr as Error);
        throw loginErr;
      }
    } finally {
      setIsLoading(false);
    }
  }, [credential]);

  return {
    getToken,
    isLoading,
    error
  };
};