import { EuiFlexGroup, EuiFlexItem, EuiTitle } from '@elastic/eui';
import { ResultViewProps } from '@elastic/react-search-ui-views';
import { Spinner } from '@fluentui/react-components';
import { useMemo, useState } from 'react';

import { DetailsModal } from '../modals/DetailsModal';
import { EasyUser } from '../EasyUser';
import { QuickActions } from '../QuickActions';
import { useGraphWithCredential } from '../../hooks/useGraphWithCredential';

interface EasyPresenceViewProps {
  resultView: ResultViewProps;
}

export const EasyPresenceView: React.FC<EasyPresenceViewProps> = ({
  resultView,
}) => {
  const { graph, isLoading } = useGraphWithCredential(["Presence.Read.All", "User.Read.All"]);

  const [isDetailModalVisible, setIsDetailModalVisible] =
    useState<boolean>(false);
  const [availability, setAvailability] = useState<string | undefined>(undefined);

  const handleDetailModalClose = () => {
    setIsDetailModalVisible(false);
  };

  const handleDetailModalOpen = () => {
    setIsDetailModalVisible(true);
  };
  
  const easyUserProps = useMemo(() => ({
    userId: resultView?.result?.id?.raw,
    displayName: resultView?.result?.display_name?.raw,
    resultType: resultView?.result?.type?.raw,
  }), [resultView?.result?.id?.raw, resultView?.result?.display_name?.raw, resultView?.result?.type?.raw]);

  const handlePresenceChange = (newAvailability: string | undefined) => {
    setAvailability(newAvailability);
  };

  return (
    <>
      {resultView?.result ? (
        <EuiFlexGroup
          className={
            'sui-result-presence ' +
            ' presenceView card-' +
            (availability?.toLowerCase() ?? 'presenceunknown')
          }
          gutterSize="m"
          responsive={false}
          wrap={false}
        >
          <EuiFlexItem grow={false}>
            <EasyUser
              {...easyUserProps}
              graph={graph}
              isLoading={isLoading}
              onPresenceChange={handlePresenceChange}
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiFlexGroup direction="column" gutterSize="none">
              <EuiFlexItem>
                <EuiTitle size="xs">
                  <h1
                    style={{
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    }}
                  >
                    {resultView.result.display_name?.raw}
                  </h1>
                </EuiTitle>
              </EuiFlexItem>
              <EuiFlexItem grow={false}>
                <QuickActions
                  resultView={resultView}
                  handleDetailModalOpen={handleDetailModalOpen}
                />
              </EuiFlexItem>
            </EuiFlexGroup>
          </EuiFlexItem>
        </EuiFlexGroup>
      ) : (
        <>
          <Spinner size="small" />
        </>
      )}

      <DetailsModal
        resultView={resultView}
        isOpen={isDetailModalVisible}
        onClose={handleDetailModalClose}
      />
    </>
  );
};
